import React from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authAction";
import IntelLogo from "../../static/img/Intel.png";
import KMVLogo from "../../static/img/kmv.png";
import AISCLogo from "../../static/img/aisc.png";
import styled from "styled-components";

const Logo = styled.img`
  width: auto;
  max-width: 100%;
  height: auto !important;
  max-height: 60px;
`;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

    // getEventsList = async () => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/certificate/events`,
  //       {
  //         headers: {
  //           'x-access-token': localStorage.getItem('jwtToken'),
  //           Accept: 'application/json',
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );

  //     const result = await response.json();
  //     this.setState({ eventList: result });
  //   } catch (error) {
  //     console.error('Error fetching certificate list:', error);
  //   }
  // };

  getProfile = () => {
    const id = localStorage.getItem("studentId");
    fetch(`${process.env.REACT_APP_API_URL}/users/students/` + id)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  };

  componentDidMount() {
    this.getProfile();
     // this.getEventsList();
  }

  render() {
    return (
      <React.Fragment>
        <div className="position-fixed w-100 header-wrapper">
          <div className="header-container">
            <div className="row header-logos-container content-container py-md-2">
              <div className="col-4 header-col d-flex align-items-center justify-content-start">
                <Logo className="header-logo" src={KMVLogo} alt="KMVLogo" />
              </div>
              <div className="col-4 header-col d-flex align-items-center justify-content-center">
                <Logo
                  className="header-logo aisc-logo"
                  src={AISCLogo}
                  alt="aiscLogo"
                />
              </div>
              <div className="col-4 header-col d-flex align-items-center justify-content-end">
                <Logo
                  className="header-logo intel-logo p-md-2"
                  src={IntelLogo}
                  alt="IntelLogo"
                />
              </div>
            </div>
          </div>

          {!this.props.hideSubHeader && (
            <header className="header px-2 px-md-0">
              <nav
                className={
                  "navbar navbar-expand-lg py-0  " +
                  (this.props.bgColor && this.props.bgColor === "white"
                    ? "custom-nav white-bg"
                    : "")
                }
              >
                <div className="container">
                  <ul style={{ display: "contents" }}>
                    <li className="nav-item">
                      <a className="nav-link page-scroll" href="/dashboard">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link page-scroll" href="/letslearn">
                        Let's Learn
                      </a>
                    </li>
                    <li className="nav-item" onClick={this.onLogoutClick}>
                      <a className="nav-link page-scroll" href="/event">
                        Events
                      </a>
                    </li>
                    <li className="nav-item" onClick={this.onLogoutClick}>
                      <a className="nav-link page-scroll" href="/challenge">
                        Challenges
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link page-scroll"
                        href={process.env.REACT_APP_FORUM_URL}
                      >
                        Forum
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link page-scroll" href="/blog">
                        Blogs
                      </a>
                    </li>
                    <li className="nav-item" onClick={this.onLogoutClick}>
                      <a href="/AIProjects" className="nav-link page-scroll">
                        AI Projects
                      </a>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        href="#"
                        className="nav-link border-0 bg-transparent"
                        id="indiaAIDropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Impact Festival
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="indiaAIDropdown"
                      >
                        <a className="dropdown-item" href="/india-ai-impact-festival-2024">
                          2024
                        </a>
                      </div>
                    </li>
                    {/* <li
                      className="dropdown"
                      onClick={this.handleCertificateGeneration}
                    >
                      <a
                        href="#"
                        className="nav-link page-scroll"
                        id="learningResourcesDropdownMenu"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        CBP
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-lg-right"
                        aria-labelledby="learningResourcesDropdownMenu"
                      >
                        <a
                          className="dropdown-item"
                          target="_blank"
                          // This URL needs to be replaced with the real one
                          href="https://docs.google.com/forms/d/e/1FAIpQLScc6kpvgkkVRtDVIV83XGTAPgP01U1bXreaR-7xZfmmH8y2cQ/viewform"
                        >
                          Register For CBP
                        </a>
                        {this.state.eventList?.length > 0 && (
                          <a
                            className="dropdown-item"
                            href="/generate-certificate"
                          >
                            Download Certificate
                          </a>
                        )}
                      </div>
                    </li> */}
                  </ul>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ml-auto py-1">
                      <li className="nav-item d-flex align-items-center">
                        <a className="nav-link page-scroll " href="/profile">
                          {this.state.data.map((item) => item.first_name)}
                        </a>
                      </li>
                      <li className="nav-item d-flex align-items-center">
                        <div className="dropdown">
                          <div
                            className="profile-picture"
                            style={{
                              backgroundImage: `url(${this.state.data.map(
                                (item) => item.profile_pic
                              )})`,
                            }}
                          ></div>

                          <div className="dropdown-content shadow">
                            <a href="/profile" style={{ color: "black" }}>
                              Profile{" "}
                            </a>

                            <div className="border-line"></div>
                            <a
                              href="/"
                              onClick={() => this.props.logoutUser()}
                              style={{ color: "black" }}
                            >
                              Logout
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </header>
          )}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(Header);
